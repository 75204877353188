<template>
  <div class="pb-10">
    <v-row justify="center" align="start" no-gutters class="mb-3">
      <v-col cols="12" lg="8">
        <v-card>
          <v-card-title> Dados do seu usuário </v-card-title>
          <v-card-text>
            <form-user
              :value="user"
              button-text="Salvar dados do usuário"
              :mode-edit="true"
              @submit="updateUser"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="start" no-gutters class="mb-3">
      <v-col cols="12" lg="8">
        <v-card>
          <v-card-title> Alterar senha </v-card-title>
          <v-card-text>
            <form-password
              button-text="Salvar senha nova"
              @submit="updatePassword"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="start" no-gutters>
      <v-col cols="12" lg="8">
        <v-card>
          <v-card-title> Dados pessoais </v-card-title>
          <v-card-text>
            <warning-section v-if="isTherapist">
              <span>
                Terapeutas: Os dados abaixo dados serão utilizados para
                <strong>agendamento</strong>! É muito importante que sejam
                preenchidos corretamente e de forma mais detalhada possível para
                que o paciente encontre você de forma mais fácil.
              </span>
            </warning-section>
            <form-user-data
              :value="userData"
              button-text="Salvar dados pessoais"
              @submit="updateUserData"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  getByToken,
  update,
  updatePassword,
} from "@/services/external/users-service";
import {
  getUserDataByToken,
  updateUserDataLoggedUser,
} from "@/services/external/users-data-service";

export default {
  components: {
    "form-user": () =>
      import("@/domains/user/shared/presentation/components/FormUser"),
    "form-user-data": () =>
      import("@/domains/user/shared/presentation/components/FormUserData"),
    "form-password": () =>
      import("@/domains/user/shared/presentation/components/FormPassword"),
  },
  data: () => ({
    user: {},
    userData: {},
  }),
  computed: {
    isTherapist() {
      return this.$store.getters["login/isTherapist"];
    },
  },
  created() {
    this.loadUser();
    this.loadUserData();
  },
  methods: {
    async loadUser() {
      try {
        let response = await getByToken();
        this.user = response.data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    async loadUserData() {
      try {
        let response = await getUserDataByToken();
        this.userData = response.data;
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    async updateUser(user) {
      try {
        this.$store.dispatch("loading/openDialog");
        await update(user);
        this.$toasted.global.success({
          message:
            "Dados salvos com sucesso! Faça login novamente para que as alterações façam efeito",
        });
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async updateUserData(userData) {
      try {
        this.$store.dispatch("loading/openDialog");
        await updateUserDataLoggedUser(userData);
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async updatePassword({ oldPassword, newPassword, confirmNewPassword }) {
      try {
        this.$store.dispatch("loading/openDialog");
        await updatePassword({ oldPassword, newPassword, confirmNewPassword });
        this.$toasted.global.defaultSuccess();
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style></style>
